import { PageProps } from 'gatsby';
import { DataFieldProps, Form } from 'packages/formidable';
import { Facia } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '~/components/Button';
import CMSView from '~/components/View';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceArticleExtract: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => {
  const [urls, setUrls] = useState<string[]>();
  const handleOnSubmit = async ({
    action,
    url,
  }: {
    action: 'extract-content' | 'extract-urls';
    url: string;
  }): Promise<void> => {
    if ('extract-urls' === action) {
      const result = await Facia.call({ kind: 'extract-urls', q: url });
      setUrls(result);
    }
  };

  const handleOnClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const index = event.currentTarget.getAttribute('data-index');
    if (index) {
      const url = urls && urls[parseInt(index, 10)];
      if (url) {
        const result = await Facia.call({ kind: 'extract-content', q: url });
        console.info('result', result);
      }
    }
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        Extraction des pages
        <Form
          datas={
            [
              {
                componentType: 'input',
                name: 'url',
                required: true,
              },
              {
                componentType: 'input',
                name: 'action',
                options: [
                  {
                    label: 'Contenu',
                    value: 'extract-content',
                  },
                  {
                    label: 'Liste des URLs',
                    value: 'extract-urls',
                  },
                ],
                type: 'radio',
              },
            ] as DataFieldProps[]
          }
          name="extract"
          onSubmit={handleOnSubmit}
        />
        {urls &&
          urls.map((url, index) => (
            <div key={url}>
              {url}{' '}
              <Button data-index={index} onClick={handleOnClick}>
                Contenu
              </Button>
            </div>
          ))}
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceArticleExtract);
